// Normalize CSS across browsers
import "./src/normalize.css";

// Custom CSS styles
import "./src/style.css"

// Slick Carousel styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// React Toastify styles
import "react-toastify/dist/ReactToastify.css"

import { createRoot } from "react-dom/client"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container)
    root.render(element)
  }
}
